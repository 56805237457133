import { mix } from './mix.mjs';
import { hex, rgba, hsla } from 'style-value-types';
import { invariant } from 'hey-listen';

const mixLinearColor = (from, to, v) => {
    const fromExpo = from * from;
    const toExpo = to * to;
    return Math.sqrt(Math.max(0, v * (toExpo - fromExpo) + fromExpo));
};
const colorTypes = [hex, rgba, hsla];
const getColorType = (v) => colorTypes.find((type) => type.test(v));
const notAnimatable = (color) => `'${color}' is not an animatable color. Use the equivalent color code instead.`;
const mixColor = (from, to) => {
    const fromColorType = getColorType(from);
    const toColorType = getColorType(to);
    invariant(!!fromColorType, notAnimatable(from));
    invariant(!!toColorType, notAnimatable(to));
    invariant(fromColorType.transform === toColorType.transform, "Both colors must be hex/RGBA, OR both must be HSLA.");
    if (!fromColorType ||
        !toColorType ||
        fromColorType.transform !== toColorType.transform) {
        return (p) => `${p > 0 ? to : from}`;
    }
    const fromColor = fromColorType.parse(from);
    const toColor = toColorType.parse(to);
    const blended = Object.assign({}, fromColor);
    const mixFunc = fromColorType === hsla ? mix : mixLinearColor;
    return (v) => {
        for (const key in blended) {
            if (key !== "alpha") {
                blended[key] = mixFunc(fromColor[key], toColor[key], v);
            }
        }
        blended.alpha = mix(fromColor.alpha, toColor.alpha, v);
        return fromColorType.transform(blended);
    };
};

export { mixColor, mixLinearColor };
