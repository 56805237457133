import { __read } from 'tslib';
import { useCallback, useRef, useState } from 'react';
import { useUnmountEffect } from './use-unmount-effect.mjs';

function useForceUpdate() {
    var unloadingRef = useRef(false);
    var _a = __read(useState(0), 2), forcedRenderCount = _a[0], setForcedRenderCount = _a[1];
    useUnmountEffect(function () { return (unloadingRef.current = true); });
    return [
        useCallback(function () {
            !unloadingRef.current && setForcedRenderCount(forcedRenderCount + 1);
        }, [forcedRenderCount]),
        forcedRenderCount,
    ];
}

export { useForceUpdate };
